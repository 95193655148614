import React, { useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; // Import Font Awesome Icon Component
import { faFacebookF, faInstagram } from '@fortawesome/free-brands-svg-icons'; // Import specific icons
import { gsap } from 'gsap'; // Import GSAP for animations
import './App.css'; // Import the CSS file for styles

const Hadlet = () => {
  const buttonsRef = useRef();

  useEffect(() => {
    gsap.fromTo(
      buttonsRef.current.children,
      { scale: 1 },
      {
        scale: 1.05,
        duration: 0.5,
        yoyo: true,
        repeat: -1,
        ease: 'power1.inOut',
        stagger: 0.2,
      }
    );
  }, []);

  return (
    <div className="container">
      <div className="content">
        <div className="logo">
          <img
            src="https://i.ibb.co/zQWb9zB/jpg-2.png"
            alt="Hadlet logo with text 'HadLet Épicerie fine'"
          />
        </div>
        <h1>Location</h1>
        <p>MONASTIR, ROND POINT NAFOURA</p>
        <h1>Links</h1>
        <div className="buttons" ref={buttonsRef}>
          <a href="https://www.facebook.com/HADLET1" className="btn">
            <FontAwesomeIcon icon={faFacebookF} /> Facebook
          </a>
          <a href="https://www.instagram.com/hadlet_epicerie_fine/" className="btn">
            <FontAwesomeIcon icon={faInstagram} /> Instagram
          </a>
          <a href="#" className="btn">
            About Us
          </a>
        </div>
      </div>
      <div className="footer">
        <p>&copy; 2024 Codify.TN All rights reserved.</p>
      </div>
    </div>
  );
};

export default Hadlet;
