import React from 'react';
import Hadlet from './Hadlet'; // Import the Hadlet component

function App() {
  return (
    <div>
      <Hadlet /> {/* Use the Hadlet component */}
    </div>
  );
}

export default App;
